
export default {

    computed: {

        is_logged_in: {
            get: function()
            {
                return this.$store.state.is_logged_in;
            },
            set: function(value)
            {
                this.$store.commit('set_state', { key: 'is_logged_in', value });
            }
        },

        ip_address_has_valid_access: {
            get: function()
            {
                return this.$store.state.ip_address_has_valid_access;
            },
            set: function(value)
            {
                this.$store.commit('set_state', { key: 'ip_address_has_valid_access', value });
            }
        },

        is_subscription_access: {
            get: function()
            {
                return this.$store.state.is_subscription_access;
            },
            set: function(value)
            {
                this.$store.commit('set_state', { key: 'is_subscription_access', value });
            }
        },

        is_paywall_deactivated: {
            get: function()
            {
                return this.$store.state.is_paywall_deactivated;
            },
            set: function(value)
            {
                this.$store.commit('set_state', { key: 'is_paywall_deactivated', value });
            }
        },

        is_email_known: {
            get: function()
            {
                return this.$store.state.is_email_known;
            },
            set: function(value)
            {
                this.$store.commit('set_state', { key: 'is_email_known', value });
            }
        }

    },

    methods: {

        async login(form) {

            let
                response
            ;


            form.device_name = this.$store.getters.device_name;


            response = await window.paywall.axios.post('/user/authenticate', form);


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'authentication' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'authentication' );

                }

                return false;

            }
            else if (
                ! paywall._.isString(response)
            ) {

                this.push_error_message( this.__('Error! We could not find the problem. Please try again.'), false, 'authentication' );

                return false;

            }


            this.push_success_message (this.__('We have logged you in'), false, 'authentication' );

            window.paywall.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response;

            localStorage.setItem('paywall.token', response);

            this.is_logged_in = true;

            return true;

        },


        async log_out(){

            let
                response
            ;

            response = await window.paywall.axios.delete('/user/log-out');

            if ( ! response.success ) {

                this.push_error_message( response.message,true,'authentication');

                this.is_logged_in = false;

                localStorage.removeItem('paywall.token');
                localStorage.removeItem('paywall.is_subscription_access');

                return false;

            }

            this.push_success_message( response.message,true,'authentication');

            this.is_logged_in = false;

            localStorage.removeItem('paywall.token');
            localStorage.removeItem('paywall.is_subscription_access');

            return true;

        },


        async fetch_is_subscription_access(){                       let debug_id = Debug.init_method('fetch_is_subscription_access',false);
            
            let
                response,
                url = '/is-subscription-access'
            ;

            if ( this.is_logged_in ) {

                url = '/user/is-subscription-access'

            }

            response = await window.paywall.axios.get( url,{
                params: {
                    site_id: this.site.id
                }
            });                                                                                                         Debug.log(debug_id,365,response);

            if (
                ! response.hasOwnProperty('is_subscription_access')
            ) {                                                                                                         Debug.log(debug_id,136,true);

                this.is_subscription_access = false;

                localStorage.setItem('paywall.is_subscription_access', false);

                return false;

            }                                                                                                           Debug.log(debug_id,140,true);


            if (
                ! response.hasOwnProperty('ip_address_has_valid_access')
            ) {                                                                                                         Debug.log(debug_id,136,true);

                this.ip_address_has_valid_access = false;

                return false;

            }

            this.is_subscription_access = response.is_subscription_access;

            localStorage.setItem('paywall.is_subscription_access', response.is_subscription_access);

            this.ip_address_has_valid_access = response.ip_address_has_valid_access;


            return true;

        },


        async send_forgot_password(form)
        {                                                                                                               let debug_id = Debug.init_method('send_forgot_password',true);
            let
                response
            ;


            form.redirect_url = location.href;


            response = await window.paywall.axios.post('/user/forgot-password', form);                                             Debug.log(debug_id,167,response);

            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'authentication' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'authentication' );

                }

                return false;

            }
            else if (
                ! response.hasOwnProperty('sent')
                || response.sent !== true
            ) {

                this.push_error_message(this.__('We could not send and forgot email. Refresh the page and try again.'),false, 'authentication');

                return false;

            }


            this.push_success_message(this.__('We have sent you an e-mail reset link'),false, 'authentication');

            return true;

        },


        async save_password(form) {                                                   let debug_id = Debug.init_method('save_password',false);

            let
                response
            ;

            response = await window.paywall.axios.patch('/user/save-password', form);                                             Debug.log(debug_id,167,response);


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'authentication' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'authentication' );

                }

                return false;

            }


            this.push_success_message(this.__('We have saved your password'),false, 'authentication');

            return true;

        },


        update_is_paywall_deactivated() {

            if ( typeof location === 'undefined' )
                return false;

            let
                query = location.search,
                is_found = query.indexOf('utm_source=newsletter&newsletter') > -1
            ;

            this.is_paywall_deactivated = is_found;

        }

    }

}
