
// Base tools setup
window.paywall = {};


// Install lodash
window.paywall._ = require('lodash');


// Install debugger
require('./debugger');


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

var axios = paywall.axios = require('axios');

window.paywall.axios = axios;

window.paywall.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.paywall.axios.defaults.baseURL = process.env.MIX_APP_URL + '/api';


// const httpsAgent = new https.Agent({ rejectUnauthorized: false });


// Make sure data from request gets responded on await instead of reject
window.paywall.axios.interceptors.response.use(function (response) {

    return response.data;

}, function (error) {

    return Promise.resolve(error.response.data);

});


// Fire handle method
import Vue from 'vue';

import Vuex from 'vuex';

// import 'es6-promise/auto'; // Using promises on IE (Vuex requires this)

import external_store from './stores/external';

import moment from 'moment';

import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faEnvelope as falEnvelope,
    faNewspaper as falNewspaper,
    faUnlock as falUnlock,
    faHandshake as falHandshake,
    faLock as falLock,
    faSpinner as falSpinner,
    faGift as falGift,
    faGlobeEurope as falGlobeEurope,
    faPaste as falPaste,
    faUsersClass as falUsersClass,
    faFileSpreadsheet as falFileSpreadsheet,
} from '@fortawesome/pro-light-svg-icons';

import {
    faLock as farLock,
    faAngleLeft as farAngleLeft,
    faAngleRight as farAngleRight,
    faTimesCircle as farTimesCircle,
    faCheckCircle as farCheckCircle,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faLock as fasLock,
    faTimes as fasTimes,
    faCheck as fasCheck,
    faMinus as fasSolid,
    faPlus as fasPlus,
    faAngleLeft as fasAngleLeft,
    faAngleRight as fasAngleRight
} from '@fortawesome/pro-solid-svg-icons'

import {
    faSpinnerThird as fadSpinnerThird
} from '@fortawesome/pro-duotone-svg-icons'

import authentication from './mixins/authentication';

import messages from './mixins/messages';

import navigation from './mixins/navigation';

import translation from './mixins/translation';

import site from './mixins/site';

import profile from './mixins/profile';

import subscriptions from './mixins/subscriptions';

import invoices from './mixins/invoices';

import forms from './mixins/forms';

async function handle() {


    // Prepare authentication
    let token = localStorage.getItem('paywall.token');

    if ( ! paywall._.isNil(token) ) {

        window.paywall.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    }


    // Install Vue
    Vue.config.productionTip = false;


    // Setup data store
    Vue.use(Vuex);

    const store = new Vuex.Store( external_store );


    // Commit default values
    store.commit('set_state', {
        key: 'site',
        value: JSON.parse( window.site )
    });

    store.commit('set_state', {
        key: 'languages',
        value: require('../lang/da.json')
    });


    let is_logged_in = ! paywall._.isNil( localStorage.getItem('paywall.token') );

    store.commit('set_state', {
        key: 'is_logged_in',
        value: is_logged_in
    });


    let is_subscription_access = ! paywall._.isNil(
        localStorage.getItem('paywall.is_subscription_access')
    ) && localStorage.getItem('paywall.is_subscription_access') === 'true';

    store.commit('set_state', {
        key: 'is_subscription_access',
        value: is_subscription_access
    });


    let show_popup = (localStorage.getItem('paywall.popup_closed_at'))
        ? localStorage.getItem('paywall.popup_closed_at')
        : false;

    store.commit('set_state', {
        key: 'popup_closed_at',
        value: show_popup
    });


    // Install fontawesome vue component and additional sizing features etc.
    Vue.component('font-awesome-icon', FontAwesomeIcon);

    Vue.component('font-awesome-layers', FontAwesomeLayers);

    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

    library.add(
        falEnvelope,
        falNewspaper,
        falUnlock,
        falHandshake,
        falLock,
        farLock,
        fasLock,
        falSpinner,
        fadSpinnerThird,
        farAngleLeft,
        farTimesCircle,
        farCheckCircle,
        farAngleRight,
        falGift,
        falGlobeEurope,
        falPaste,
        falUsersClass,
        falFileSpreadsheet,
        fasTimes,
        fasCheck,
        fasSolid,
        fasPlus,
        fasAngleLeft,
        fasAngleRight
    );


    // Load views for paywall applications
    Vue.component('paywall-view', require('./views/PaywallView').default );

    Vue.component('paywall-view-logged-in-text', require('./views/PaywallLoggedInText').default );

    Vue.component('paywall-view-trial-button', require('./views/PaywallTrialButton').default );

    Vue.component('paywall-view-selfservice', require('./views/SelfserviceView').default );

    Vue.component('paywall-view-invoices', require('./views/InvoicesView').default );

    Vue.component('paywall-view-menu', require('./views/MenuView').default );

    Vue.component('paywall-view-ip-subscription-form', require('./views/IpSubscriptionFormView').default );

    Vue.component('paywall-view-signup-form', require('./views/SignupFormView').default );

    Vue.component('paywall-view-advertisement-form', require('./views/AdvertisementFormView').default );

    Vue.component('paywall-view-buy-subscription-form', require('./views/BuySubscriptionFormView').default );


    // Load components for paywall application
    Vue.component('messages', require('./components/Messages').default );

    Vue.component('loader', require('./components/Loader').default );


    // Prepare global data mixins
    Vue.mixin(authentication);

    Vue.mixin(messages);

    Vue.mixin(navigation);

    Vue.mixin(translation);

    Vue.mixin(site);

    Vue.mixin(profile);

    Vue.mixin(subscriptions);

    Vue.mixin(invoices);

    Vue.mixin(forms);


    Vue.filter('toCurrencyDK', function (value) {
        if (typeof parseFloat(value) !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('da-DK', {
            style: 'currency',
            currency: 'DKK',
            currencyDisplay : 'code'
        });

        return formatter.format(parseFloat(value)).split(/\s/).reverse().join(' ');
    });


    Vue.filter('formatDate', function (value) {

        return moment(value).format('DD[/]MM [-] YYYY');

    });


    /**********************************
     * Startup application
     **********************************/

    if ( document.getElementById('paywall_view') !== null ) {

        window.paywall.paywall_view = new Vue({
            store
        });

        window.paywall.paywall_view.$mount('#paywall_view');

    }

    if ( document.getElementById('paywall_logged_in_text') !== null ) {

        window.paywall.logged_in_text_view = new Vue({
            store
        });

        window.paywall.logged_in_text_view.$mount('#paywall_logged_in_text');

    }


    if ( document.getElementById('paywall_trial_button_desktop') !== null ) {

        window.paywall.trial_button_desktop_view = new Vue({
            store
        });

        window.paywall.trial_button_desktop_view.$mount('#paywall_trial_button_desktop');

    }
    if ( document.getElementById('paywall_trial_button_mobile') !== null ) {

        window.paywall.trial_button_mobile_view = new Vue({
            store
        });

        window.paywall.trial_button_mobile_view.$mount('#paywall_trial_button_mobile');

    }


    if ( document.getElementById('paywall_menu') !== null ) {

        window.paywall.menu_view = new Vue({
            store
        });

        window.paywall.menu_view.$mount('#paywall_menu');

    }

    if ( document.getElementById('paywall_mobile_menu') !== null ) {

        window.paywall.mobile_menu_view = new Vue({
            store
        });

        window.paywall.mobile_menu_view.$mount('#paywall_mobile_menu');

    }

    if ( document.getElementById('paywall_selfservice') !== null ) {

        window.paywall.selfservice_view = new Vue({
            store
        });

        window.paywall.selfservice_view.$mount('#paywall_selfservice');

    }

    if ( document.getElementById('paywall_invoices') !== null ) {

        window.paywall.invoices_view = new Vue({
            store
        });

        window.paywall.invoices_view.$mount('#paywall_invoices');

    }

    if ( document.getElementById('paywall_ip_subscription_form') !== null ) {

        window.paywall.ip_subscription_form_view = new Vue({
            store
        });

        window.paywall.ip_subscription_form_view.$mount('#paywall_ip_subscription_form');

    }

    if ( document.getElementById('paywall_forms') !== null ) {

        window.paywall.forms_view = new Vue({
            store
        });

        window.paywall.forms_view.$mount('#paywall_forms');

    }

}

document.addEventListener('paywall_do_load', () => {

    handle();

});
