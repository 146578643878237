<template>

    <div id="paywall_tailwind">

        <div v-if="show_forms">

            <PopupForm v-if="is_unlimited_users_allowed"
               :sales_headline="__('Get free access when traveling on all your devices')"
               :is_unlimited_users_allowed="is_unlimited_users_allowed"
               :endpoint="final_endpoint('/register-subuser')">
            </PopupForm>

            <PopupForm v-else
                :sales_headline="__('Get our newsletter from <br>:site_name :count times a :frequency', {
                    site_name: site.name,
                    count: site.newsletter_count,
                    frequency: __( site.newsletter_frequency )
                })"
               :endpoint="final_endpoint('/register-mailchimp-subscriber')">
            </PopupForm>

        </div>

    </div>

</template>

<script>

import PopupForm from "../widgets/PopupForm";
export default {

    name: "IpSubscriptionFormView",
    components: {PopupForm},
    data : function () {
        return {
            is_unlimited_users_allowed: false,
            show_forms: false,
        }
    },
    async created(){

        await this.fetch_is_subscription_access();

        await window.paywall.axios.post('/site/' + this.site.id + '/check-subscription')
            .then(response => {

                this.is_unlimited_users_allowed = response.is_unlimited_users_allowed;

            }).catch(error => {

                console.log(error.response.status);

            });

        if ( this.ip_address_has_valid_access && !this.is_logged_in && this.check_popup_date() ) {

            this.show_forms = true;

        }
    },

    methods: {

        final_endpoint(endpoint){

            return '/site/' + this.site.id + endpoint;

        }

    }


}
</script>

<style scoped>

</style>
