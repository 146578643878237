<template>

    <div class="w-full">

        <input
            class="appearance-none block w-full bg-white text-gray-700 border border-1 border-[#C1C1C1] py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-dark-blue disabled:opacity-50 disabled:bg-gray-100"
            v-model="_value"
            :type="type"
            :placeholder="placeholder_formatted"
            :autofocus="is_autofocus"
            :aria-autocomplete="autocomplete"
            :autocomplete="autocomplete"
            :disabled="is_disabled"
            :aria-disabled="is_disabled"
        />

    </div>

</template>
<script>

export default {

    name: 'FormInputField',

    props: {
        placeholder: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        is_required: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            required: false
        },
        autocomplete: {
            type: String,
            required: false,
            default: ''
        },
        is_autofocus: {
            type: Boolean,
            required: false,
            default: false
        },
        is_disabled: {
            type: Boolean,
            default: false
        }
    },

    emits: [
        'update:modelValue'
    ],

    computed: {

        placeholder_formatted() {
            if (this.is_required) {
                return this.__(this.placeholder) + '*';
            }
            return this.__(this.placeholder);
        },

        _value: {
            get: function() {
                return this.value;
            },
            set: function(value) {
                this.$emit('input', value);
            }
        }

    },

}
</script>
