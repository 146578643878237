var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_subscription_access === false && _vm.is_paywall_deactivated === false),expression:"is_subscription_access === false && is_paywall_deactivated === false"}],staticClass:"px-5 sm:px-0",style:(_vm.short_story_styles),domProps:{"innerHTML":_vm._s(_vm.short_story)}}),_vm._v(" "),_c('div',{attrs:{"id":"paywall_tailwind"}},[(_vm.is_subscription_access === false && _vm.is_paywall_deactivated === false)?_c('div',{staticClass:"top-0 w-full flex flex-col items-start justify-start z-10 bg-transparent pb-10",class:{
                'pt-12': ! _vm.is_short_hidden(),
                'pt-0': _vm.is_short_hidden(),
                'absolute': _vm.is_shaded_short_story,
                'relative': ! _vm.is_shaded_short_story,
             }},[_c('div',{staticClass:"w-full flex justify-start items-end",class:{
                    'h-[134px]': ! _vm.is_short_hidden() && _vm.is_shaded_short_story,
                    'h-[30px]': ! _vm.is_short_hidden() && ! _vm.is_shaded_short_story,
                    'h-[54px]': _vm.is_short_hidden(),
                 },staticStyle:{"background":"linear-gradient(0deg, #FFFFFF 33.5%, rgba(255, 255, 255, 0) 100%)"},attrs:{"id":"top_shade_message"}},[_c('h2',{staticClass:"py-4 font-bold text-2xl px-5 sm:px-0",class:`text-${ _vm.site.color }`},[_vm._v("\n\n                    "+_vm._s(_vm.__( _vm.headline ))+"?\n\n                ")])]),_vm._v(" "),_c('div',{staticClass:"bg-white w-full pb-8"},[_c('a',{staticClass:"block border-green-dark border-2 w-full bg-green-dark bg-opacity-10 flex flex-col p-5 cursor-pointer",attrs:{"href":_vm.trial_url}},[_c('div',{staticClass:"flex flex-col sm:flex-row justify-between w-full items-center mb-6 sm:mb-0"},[_c('div',{staticClass:"flex flex-col mb-4 sm:mb-none"},[_c('h2',{staticClass:"text-black font-bold w-full text-xl mb-2"},[_vm._v(_vm._s(_vm.__(_vm.site.trial_week_count === 1 ? 'Try :site_name free for :trail_week_count week' : 'Try :site_name free for :trail_week_count weeks', {
                                'site_name': _vm.site.name,
                                'trail_week_count': _vm.site.trial_week_count
                            })))]),_vm._v(" "),_c('div',{staticClass:"text-sm text-gray-dark"},[_vm._v(_vm._s(_vm.__('Get access right away - not binding - no creditcard')))])]),_vm._v(" "),_c('div',{staticClass:"text-lg font-bold",staticStyle:{"width":"fit-content"}},[_vm._v(_vm._s(_vm.__('Free')))])]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 sm:grid-rows-1 gap-3",class:`grid-rows-${ _vm.site.trial_subscription_pros.length } sm:grid-cols-${ _vm.site.trial_subscription_pros.length }`},[_c('span',{staticClass:"hidden sm:grid-cols-4 sm:grid-cols-3 sm:grid-cols-2 sm:grid-cols-1"}),_vm._v(" "),_c('span',{staticClass:"hidden grid-rows-1 grid-rows-2 grid-rows-3 grid-rows-4"}),_vm._v(" "),_vm._l((_vm.site.trial_subscription_pros),function(pro){return _c('div',{staticClass:"bg-white flex flex-col justify-start items-center p-3 text-center shadow"},[_c('font-awesome-icon',{staticClass:"mb-3",attrs:{"icon":['fal', pro.icon],"size":"2x","fixed-width":""}}),_vm._v(" "),_c('div',{staticClass:"text-xs",domProps:{"innerHTML":_vm._s(_vm.__(pro.text, {
                                     trial_week_count: _vm.site.trial_week_count,
                                     newsletter_frequency: _vm.__(_vm.site.newsletter_frequency)
                                 }))}})],1)})],2)])]),_vm._v(" "),_c('LoginWidget',{staticClass:"mb-25"}),_vm._v(" "),(_vm.is_logged_in)?_c('div',{staticClass:"bg-white w-full flex flex-col sm:flex-row justify-start items-start sm:items-center px-4 py-4"},[_c('p',{staticClass:"text-sm mr-6 mb-4 sm:mb-0 leading-6"},[_vm._v("\n\n                    "+_vm._s(_vm.__('You do not have access to this content with your current active subscriptions.'))+"\n\n                ")]),_vm._v(" "),_c('a',{staticClass:"block border-2 border-black text-white hover:bg-white hover:text-black w-min sm:ml-auto mb-4 sm:mb-0 whitespace-nowrap font-bold px-4 py-4 text-lg relative mr-4",class:`bg-${ _vm.site.color } hover:text-${ _vm.site.color }`,attrs:{"href":_vm.account_url}},[_c('span',[_vm._v(_vm._s(_vm.__('Account')))])]),_vm._v(" "),_c('button',{staticClass:"bg-red border-2 border-red text-white hover:bg-white hover:text-black w-min whitespace-nowrap font-bold px-4 py-4 text-lg relative mb-4 sm:mb-0",on:{"click":function($event){$event.preventDefault();return _vm.handle_log_out.apply(null, arguments)}}},[_c('loader',{attrs:{"on":_vm.loading}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.__('Log out')))])],1)]):_vm._e(),_vm._v(" "),_c('messages',{staticClass:"bg-white w-full",attrs:{"types":['authentication','paywall']}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }