
export default {

    computed: {

        signup_form: {
            get: function() {
                return this.$store.state.signup_form;
            },
            set: function(value) {
                this.$store.commit('set_state', { key: 'signup_form', value });
            }
        },

        advertisement_form: {
            get: function() {
                return this.$store.state.advertisement_form;
            },
            set: function(value) {
                this.$store.commit('set_state', { key: 'advertisement_form', value });
            }
        },

        buy_subscription_form: {
            get: function() {
                return this.$store.state.buy_subscription_form;
            },
            set: function(value) {
                this.$store.commit('set_state', { key: 'buy_subscription_form', value });
            }
        }

    },

    methods: {

        async forms_submit(form_key, success_message) {

            this.clear_messages(0,form_key);

            let
                response,
                first_error
            ;


            response = await window.paywall.axios.post('/forms/' + form_key, this.$store.state[form_key] );


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {

                        first_error = error[0].toString();

                        this.push_error_message( error[0].toString(), false,form_key );
                    }

                }
                else {

                    first_error = response.message;

                    this.push_error_message( response.message, false, form_key );

                }

                return false;

            }
            else if (
                ! paywall._.isString(response)
            ) {

                this.push_error_message( this.__('Error! We could not find the problem. Please try again.'), false, form_key );

                return false;

            }


            this.push_success_message (this.__(success_message), false, form_key );

            this.clear_messages(5, form_key);

            return true;

        },

    }

}
