<template>

    <div id="paywall_tailwind">

        <div class="flex flex-col items-start w-full">

            <button @click="current_page = 'selfservice'" class="mb-7 text-white py-3 px-6 rounded-sm flex justify-center items-center"
                :class="`bg-${ site.color }`">

                <font-awesome-icon :icon="['far', 'angle-left']" class="mr-3 text-xl"/>

                <span class="text-base" style="line-height: 16px">{{__('Back to your account')}}</span>

            </button>

            <h1 class="text-2xl font-bold mb-7">{{__('Your invoices')}}</h1>

            <div class="shadow-special rounded-sm overflow-x-scroll w-full max-w-full">

                <table v-if="invoices.length" class="table-auto w-full">
                    <tr class="text-base border" style="color: rgba(0, 0, 0, 0.6);">
                        <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('INVOICE No.')}}</th>
                        <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('INVOICED AT')}}</th>
                        <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('DUE AT')}}</th>
                        <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('RESIDUAL AMOUNT')}}</th>
                        <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('PAID')}}</th>
                    </tr>
                    <tr v-for="invoice in invoices" class="text-md border">
                        <td class="py-5 pl-6">
                            <p v-if="invoice.iteras_id && invoice.iteras_id !== '0'" class="whitespace-nowrap">{{ invoice.iteras_id }}</p>
                            <p v-else class="whitespace-nowrap">-</p>
                        </td>
                        <td class="py-5 pl-6">
                            <p v-if="invoice.invoiced_at && invoice.invoiced_at !== '0'" class="whitespace-nowrap">{{invoice.invoiced_at | formatDate}}</p>
                            <p v-else class="whitespace-nowrap">-</p>
                        </td>
                        <td class="py-5 pl-6">
                            <p v-if="invoice.due_at && invoice.due_at !== '0'" class="whitespace-nowrap">{{invoice.due_at | formatDate}}</p>
                            <p v-else class="whitespace-nowrap">-</p>
                        </td>
                        <td class="py-5 pl-6">
                            <p v-if="invoice.amount_left" class="whitespace-nowrap">{{invoice.amount_left | toCurrencyDK }}</p>
                            <p v-else class="whitespace-nowrap">-</p>
                        </td>
                        <td class="py-5 pl-6">
                            <font-awesome-icon v-if="invoice.is_to_be_paid" :icon="['far', 'times-circle']" class="text-xl text-red"/>
                            <font-awesome-icon v-else :icon="['far', 'check-circle']" class="text-xl text-green"/>

                        </td>
                        <td class="py-5 pl-6">
                            <a :href="invoice.pdf_storage_path" v-if="invoice.pdf_storage_path" class="whitespace-nowrap underline">{{__('Inspect invoice')}}</a>
                            <p v-else class="whitespace-nowrap">-</p>
                        </td>
                        <td class="py-5 px-6">
                            <a :href="invoice.pdf_download_url" target="blank" v-if="invoice.pdf_storage_path" class="whitespace-nowrap underline">{{__('Download invoice')}}</a>
                            <p v-else class="whitespace-nowrap">-</p>
                        </td>
                    </tr>
                </table>


                <div v-else class="flex justify-center items-center" style="height: 300px">

                    <p>{{__('You have no invoices')}}</p>

                </div>

            </div>

            <messages class="mt-3" :types="['invoices']"></messages>

        </div>

    </div>

</template>

<script>


export default {
    name: "InvoicesView",
    data(){
        return {
            invoices : [],
        }
    },
    created() {
        if ( this.is_logged_in ) {

            this.fill_invoices();

        }
    },
    methods : {
        async fill_invoices() {

            this.invoices = await this.get_invoices();

        },
    }
}
</script>

<style scoped>

</style>
