
import Debug from './debugging';

window.Debug = Debug.init();

if (
   window.paywall._.isString( localStorage.debug )
    && localStorage.debug === 'true'
) {

    window.Debug.start();

}
