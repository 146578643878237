<template>

    <a href="/proeve-abonnement"
       :title="__('Trial subscription')"
       class="flex items-center justify-center"
        v-if="! is_subscription_access || ! is_logged_in">

        <font-awesome-icon
            :icon="['fas', 'angle-right']"
            class="text-[14px] mr-2 font-bold"
        ></font-awesome-icon>

        <span class="font-bold text-[14px] whitespace-nowrap">{{ __('Trial subscription') }}</span>

    </a>

</template>

<script>
export default {
    name: "PaywallBuyText"
}
</script>

<style scoped>

</style>
