
export default {

    computed: {

        popup_closed_at: {
            get: function()
            {
                return this.$store.state.popup_closed_at;
            },
            set: function(value)
            {
                this.$store.commit('set_state', { key: 'popup_closed_at', value });
            }
        },


        site: {
            get: function ()
            {
                return this.$store.state.site;
            },
            set: function(value)
            {
                this.$store.commit('set_state', { key: 'site', value })
            }
        },


        site_name() {

            return this.site.name;

        },


        trail_week_count() {

            return this.site.trail_week_count;

        },

    },
    methods: {
        check_popup_date(){
            if(this.popup_closed_at){
                let date = new Date(parseInt(this.popup_closed_at));

                date.setDate(date.getDate() + 5);

                return date <= Date.now();
            }
            else {
                return true
            }


        },

    }
}
