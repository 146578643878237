<template>

    <div id="paywall_tailwind">

        <div class="bg-white flex justify-between flex-col md:flex-row w-full md:max-w-7xl md:mx-auto"
             :class="{
                'md:justify-center': ! is_logged_in
            }" >


            <div class="w-full md:w-8/12 flex-col mb-6 mb:mb-0 mx-auto"
                 :class="{
                    'flex': ! is_logged_in,
                    'hidden': is_logged_in
                }">


                <LoginWidget
                    @login_complete="handle_login_complete"
                />


                <messages class="bg-white w-full"
                    :types="['authentication']"
                />


            </div>


            <div class="w-full md:w-6/12 flex flex-col mb-6 md:mb-0 flex"
                 v-if="current_page === 'selfservice' && is_logged_in">


                <h2 class="text-black text-2xl font-bold mb-6">{{ __('Your information') }}</h2>


                <form class="w-full grid grid-cols-2 gap-4"
                      @submit.prevent="! loading ? handle_save_info() : ''">


                    <div v-for="(field,key) in form_info"
                         :style="`grid-area: ${ key }`"
                         class="flex flex-col mb-2">

                        <label class="font-bold text-lg mb-3"
                            :for="key">

                            <span>{{ __(key) }}</span>

                            <span v-if="required_info.hasOwnProperty(key)">*</span>

                        </label>

                        <input
                            :id="key"
                            class="bg-white border-1 border-[#C1C1C1] p-3 text-base leading-6 h-auto mb-0 "
                            type="text"
                            v-model="form_info[key]"
                            v-if="key.indexOf('address') === -1"
                        />

                        <textarea
                            :id="key"
                            class="bg-white border-1 border-[#C1C1C1] p-3 text-base leading-6 h-auto mb-0"
                            v-model="form_info[key]"
                            v-else-if="key.indexOf('address') > -1">
                        </textarea>

                    </div>


                    <div style="grid-area: billing_headline;">

                        <h2 class="text-black text-2xl font-bold mb-3 mt-[60px]">{{ __('Billing information') }}</h2>

                        <p class="mb-6 text-black -text-base">{{ __('Keep fields empty, if same as your info')}}</p>

                    </div>


                    <button
                        type="submit"
                        class=" w-full text-2xl text-center border-2 border-black text-white hover:bg-white py-5 font-bold mt-3 relative z-0"
                        :class="[
                            {
                                'mb-6': messages.length === 0
                            },
                            `bg-${ site.color } hover:text-${ site.color }`
                        ]"
                        style="grid-area: save">

                        <loader
                            :on="loading"
                        />

                        <span>{{ __('Save changes')}}</span>

                    </button>


                    <messages style="grid-area: messages;"
                        :types="['profile', 'form_info']">
                    </messages>


                </form>

            </div>


            <div class="w-full md:w-5/12 flex flex-col"
                 v-if="is_logged_in && current_page === 'selfservice'">


                <div class="mb-4">

                    <div class="flex justify-between items-center mb-6">

                        <h2 class="text-lg font-bold">{{ __('Active subscriptions')}}</h2>

                        <button class="text-red font-bold"
                            v-if="is_logged_in"
                            @click="handle_log_out">

                            {{ __('Log out') }}

                        </button>

                    </div>

                    <div
                        class="mb-[15px]"
                        v-if="subscriptions.length > 0"
                        v-for="subscription of subscriptions">

                        <button
                            @click="display_group_subscription(subscription.id)"
                            v-if="subscription.has_children"
                            class="border-1 border-green-darker bg-opacity-10 py-4 px-5 flex mb-4 justify-between items-center w-full"
                            :class="{
                                'bg-green': subscription.status === 'active',
                                'bg-yellow-200': subscription.status === 'ended',
                                'bg-red': subscription.status === 'stopped',
                            }">

                            <div class="flex flex-col justify-start">

                                <div class="text-black text-md mb-2 font-bold text-left">{{ __(subscription.name) }}</div>

                                <div class="text-gray-lighta text-sm text-left">{{ __(subscription.tagline) }}</div>

                            </div>

                            <font-awesome-icon :icon="['far', 'angle-right']" class="ml-3 text-2xl text-black"/>

                        </button>

                        <div v-else
                             class="border-1 border-green-darker bg-opacity-10 py-4 px-5 flex flex-col mb-4"
                             :class="{
                                'bg-green': subscription.status === 'active',
                                'bg-yellow-200': subscription.status === 'ended',
                                'bg-red': subscription.status === 'stopped',
                            }">

                            <div class="text-black text-md mb-2 font-bold">{{ __(subscription.name) }}</div>

                            <div class="text-gray-lighta text-sm">{{ __(subscription.tagline) }}</div>

                        </div>

                    </div>

                    <p v-else>{{__('You have no active subscriptions')}}</p>

                </div>


                <button @click="current_page = 'invoice'"
                    class="mb-7 border-2 border-black text-white hover:bg-white py-3 px-6 flex justify-center items-center w-min"
                    :class="`bg-${ site.color } hover:text-${ site.color }`">

                    <span class="text-base whitespace-nowrap" style="line-height: 16px">{{__('Inspect invoices')}}</span>

                    <font-awesome-icon :icon="['far', 'angle-right']" class="ml-3 text-xl"/>

                </button>


                <div class="shadow-special flex flex-col px-6 pb-4 pt-6 w-full md:mb-6 md:mb-12">


                    <h2 class="text-black font-bold text-2xl mb-6">{{ __('Change password') }}</h2>


                    <form class="w-full flex flex-col"
                          @submit.prevent="! loading ? handle_save_password() : ''">


                        <div v-for="(field,key) in form_password"
                             class="flex flex-col mb-2">

                            <label :for="key"
                                   class="font-bold text-md mb-3">

                                <span>{{ __(key) }}</span>

                                <span v-if="required_password.hasOwnProperty(key)">*</span>

                            </label>

                            <input
                                :id="key"
                                class="border-1 border-[#C1C1C1] bg-white p-3 text-base leading-6 h-auto"
                                style="margin-bottom: 1rem!important;"
                                type="password"
                                :autocomplete="key === 'current_password' ? 'current-password' : ''"
                                v-model="form_password[key]"
                            />

                        </div>


                        <button type="submit"
                            class="border-2 border-black text-white hover:bg-white w-full text-xl text-center text-white py-5 font-bold mt-0 relative z-0"
                            :class="[
                                {
                                    'mb-6': messages.length === 0
                                },
                                `bg-${ site.color } hover:text-${ site.color }`
                            ]">

                            <loader
                                :on="loading"
                            />

                            <span>{{ __('Save new password')}}</span>

                        </button>


                        <messages class="mt-3" :types="['form_password', 'authentication']"></messages>


                    </form>

                </div>

            </div>


            <div
                class="flex justify-center w-full"
                v-if="current_page === 'group_subscription'">

                <GroupSubscriptionView
                    :parent_subscription="displayed_group_subscription"
                />

            </div>


            <div
                class="w-full"
                v-if="current_page === 'invoice'">

                <InvoicesView
                />

            </div>


        </div>

    </div>

</template>

<script>

import LoginWidget from "../widgets/LoginWidget";
import GroupSubscriptionView from "./GroupSubscriptionView";
import InvoicesView from "./InvoicesView.vue";

export default {

    name: "SelfserviceView",

    components: {
        InvoicesView,
        GroupSubscriptionView,
        LoginWidget
    },

    data() {
        return {
            loading: false,
            required_info: {
                email: true,
                billing_address: true
            },
            form_info: {
                email: '',
                first_name: '',
                last_name: '',
                position: '',
                phone: '',
                mobile: '',
                address: '',
                zip_code: '',
                city: '',
                organization: '',
                cvr: '',
                ean: ''
            },
            required_password: {
                password: true,
                new_password: true,
                new_password_confirmation: true
            },
            form_password: {
                password: '',
                new_password: '',
                new_password_confirmation: ''
            },
        }
    },


    created() {

        this.current_page = 'selfservice';

        if ( this.is_logged_in ) {

            this.refresh_form_info();

            this.refresh_subscriptions();

        }

    },


    methods: {

        display_group_subscription: function (subscription_id) {

            this.displayed_group_subscription = subscription_id;

            this.current_page = 'group_subscription';

        },

        async handle_save_info() {

            let
                is_saved,
                response
            ;

            this.loading = true;


            this.push_normal_message(this.__('Wait! We are saving your info.'),true, 'form_info');


            this.$nextTick(() => {

                window.scroll({
                    top: window.scrollY + 60,
                    left: 0,
                    behavior: 'smooth'
                });

            });


            response = await this.save_customer_profile(this.form_info);

            this.clear_messages(0,'form_info');

            this.clear_messages(5,'profile');


            this.loading = false;

        },

        async handle_save_password()
        {
            let
                response
            ;

            this.loading = true;


            this.push_normal_message(this.__('Wait! We are saving your new password.'),true, 'form_password');


            this.$nextTick(() => {

                window.scroll({
                    top: window.scrollY + 60,
                    left: 0,
                    behavior: 'smooth'
                });

            });


            response = await this.save_password( this.form_password );

            this.clear_messages(0,'form_password');

            this.clear_messages(5,'authentication');


            if ( ! response ) {

                this.loading = false;

                return;

            }

            this.form_password = {
                password: '',
                new_password: '',
                new_password_confirmation: ''
            };


            this.loading = false;
        },


        async refresh_form_info() {

            this.form_info = await this.get_customer_profile();

        },

        handle_login_complete() {                                                                                       let debug_id = Debug.init_method('handle_login_complete',true);

            this.current_page = 'selfservice';

            this.refresh_form_info();                                                                                   Debug.log(debug_id,350,true);

            this.refresh_subscriptions();                                                                               Debug.log(debug_id,352,true);

        },

        async handle_log_out() {

            this.loading = true;

            this.current_page = 'login';

            this.push_normal_message(this.__('Wait! We are processing the log out.'), true, 'paywall');

            await this.log_out();

            this.clear_messages(0,'paywall');

            this.clear_messages(3,'authentication');

            this.loading = false;

        },

    },

}
</script>

<style scoped>

form {
    grid-template-areas:
            "full_name full_name"
            "email email"
            "phone mobile"
            "job_position job_position"
            "company_name company_name"
            "address address"
            "country country"
            "cvr_number ean_number"
            "billing_headline billing_headline"
            "billing_full_name billing_full_name"
            "billing_company_name billing_company_name"
            "billing_email billing_email"
            "billing_address billing_address"
            "billing_country billing_country"
            "save save"
            "messages messages"
;
}

</style>
