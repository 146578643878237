<template>

    <div class="flex flex-col items-start w-full">

        <button
            @click="current_page = 'selfservice'"
            class="mb-7 text-white py-3 px-6 rounded-sm flex justify-center items-center"
            :class="`bg-${ site.color }`">

            <font-awesome-icon
                :icon="['far', 'angle-left']"
                class="mr-3 text-xl"
            />

            <span
                class="text-base"
                style="line-height: 16px">

                {{__('Back to your account')}}

            </span>

        </button>

        <h1 class="text-2xl font-bold mb-7">{{ __('Your Users') }}</h1>

        <div class="shadow-special rounded-sm overflow-x-scroll w-full">

            <table v-if="subscriptionGroup.length" class="table-auto w-full">
                <tr class="text-base border" style="color: rgba(0, 0, 0, 0.6);">
                    <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('NAME')}}</th>
                    <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('E-MAIL')}}</th>
                    <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('PHONE')}}</th>
                    <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('MOBILE')}}</th>
                    <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('JOB POSITION')}}</th>
                    <th class="py-5 pl-6 whitespace-nowrap" style="text-align: left">{{__('COMPANY')}}</th>
                </tr>
                <tr v-for="subscription in subscriptionGroup" class="text-md border">
                    <td class="py-5 pl-6">
                        <p v-if="subscription.name && subscription.name !== '0'" class="whitespace-nowrap">{{ subscription.name }}</p>
                        <p v-else class="whitespace-nowrap">-</p>
                    </td>
                    <td class="py-5 pl-6">
                        <p v-if="subscription.email && subscription.email !== '0'" class="whitespace-nowrap">{{subscription.email}}</p>
                        <p v-else class="whitespace-nowrap">-</p>
                    </td>
                    <td class="py-5 pl-6">
                        <p v-if="subscription.phone && subscription.phone !== '0'" class="whitespace-nowrap">{{subscription.phone}}</p>
                        <p v-else class="whitespace-nowrap">-</p>
                    </td>
                    <td class="py-5 pl-6">
                        <p v-if="subscription.mobile && subscription.mobile !== '0'" class="whitespace-nowrap">{{subscription.mobile}}</p>
                        <p v-else class="whitespace-nowrap">-</p>
                    </td>
                    <td class="py-5 pl-6">
                        <p v-if="subscription.job_title && subscription.job_title !== '0'" class="whitespace-nowrap">{{subscription.job_title}}</p>
                        <p v-else class="whitespace-nowrap">-</p>
                    </td>
                    <td class="py-5 pl-6">
                        <p v-if="subscription.company && subscription.company !== '0'" class="whitespace-nowrap">{{subscription.company}}</p>
                        <p v-else class="whitespace-nowrap">-</p>
                    </td>
                </tr>
            </table>

            <div
                v-else
                class="flex justify-center items-center"
                style="height: 300px">

                <p>{{__('You have no users')}}</p>

            </div>

        </div>

        <messages class="mt-3" :types="['invoices']"></messages>

    </div>

</template>

<script>
export default {

    name: "GroupSubscriptionView",

    props : {
        parent_subscription : Number
    },

    data() {
        return {
            subscriptionGroup : [],
        }
    },

    created() {

        this.fill_subscriptions();

    },

    methods : {

        async fill_subscriptions() {

            this.subscriptionGroup = await this.get_child_subscriptions(this.parent_subscription);

        },

    }
}
</script>

<style scoped>

</style>
