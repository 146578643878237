<template>

    <span>{{ logged_in_text }}</span>

</template>

<script>
export default {
    name: "PaywallLoggedInText",

    computed: {

        logged_in_text() {

            return this.is_logged_in
                ? this.__('My page')
                : this.__('Login');

        }

    }

}
</script>

<style scoped>

</style>
