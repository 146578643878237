
export default {

    computed: {

        subscriptions: {
            get: function() {

                return this.$store.state.subscriptions;

            },
            set: function(subscriptions) {

                this.$store.commit('set_state', {
                    key: 'subscriptions',
                    value: subscriptions
                });

            }
        }

    },

    methods: {

        async refresh_subscriptions() {

            this.subscriptions = await this.get_subscriptions();

        },

        async get_subscriptions() {

            let
                response
            ;


            response = await window.paywall.axios.get('/user/customer-subscriptions');


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'subscriptions' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'subscriptions' );

                }

                return false;

            }


            return response;

        },

        async get_child_subscriptions(parent_subscriptions) {

            let
                response
            ;

            response = await window.paywall.axios.get('/user/customer-subscriptions/' + parent_subscriptions);


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'subscriptions' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'subscriptions' );

                }

                return false;

            }


            return response;

        }

    }

}
