
export default {

    methods: {

        async get_invoices() {

            let
                response
            ;


            response = await window.paywall.axios.get('/user/invoices');


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'invoices' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'invoices' );

                }

                return false;

            }


            return response;

        },


    }

}
