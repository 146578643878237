
export default {

    methods: {

        __(key, replace = {}) {

            var translation = this.$store.state.languages[key]
                ? this.$store.state.languages[key]
                : key

            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key])
            });

            return translation;

        },

    }
    
}
