
export default {

    computed: {

        messages: {
            get: function() {

                return this.$store.state.messages;

            },
            set: function(value) {

                this.$store.commit('set_state', {
                    key: 'messages',
                    value
                });

            }
        }

    },

    methods: {

        push_normal_message( text_message, spin = false, type = 'any' )
        {
            this.push_message({
                text: this.__(text_message),
                color: 'black',
                spin,
                type
            });
        },

        push_error_message( text_message, spin = false, type = 'any' )
        {
            this.push_message({
                text: this.__(text_message),
                color: 'red',
                spin,
                type
            });
        },

        push_success_message( text_message, spin = false, type = 'any' )
        {
            this.push_message({
                text: this.__(text_message),
                color: 'green',
                spin,
                type
            });
        },

        clear_messages( wait_seconds = 0, type = 'any' )
        {
            setTimeout(() => {

                this.messages = paywall._.filter(this.messages, (message) => {

                    return message.type !== type;

                });

            }, wait_seconds * 1000);
        },

        push_message( message )
        {
            this.$store.commit('push_state', {
                key: 'messages',
                value: message
            });
        },

    }
}
