
export default {

    computed: {

        current_page: {
            get: function() {

                return this.$store.state.current_page;

            },
            set: function(page) {

                this.$store.commit('set_state', {
                    key: 'current_page',
                    value: page
                });

            }
        },

        trial_url() {

            if ( this.site.slug === 'nb_data' ) {

                return '/proeve-abonnement?site=nb_data';

            }

            return '/proeve-abonnement';

        },

        account_url() {

            return '/selvbetjening/';

        },

        contact_url() {

            return '/kontakt-os/';

        }

    },

    methods: {

        navigate_to_try_free_subscription()
        {
            location.pathname = '/proeve-abonnement';
        },


        navigate_to_account()
        {
            location.pathname = '/selvbetjening/';
        },


        navigate_to_contact() {

            location.pathname = /kontakt-os/;

        }

    }
}
