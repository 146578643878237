<template>
    <transition name="fade-slide" appear>
        <div class="fixed bottom-5 sm:right-5 sm:left-[unset] z-50 left-5 right-5 sm:w-[630px] w-[calc(100%-50px)]"  v-if="show_form">
            <button class="absolute top-0 left-0 custom-close-button bg-white rounded-full -ml-2 -mt-2 h-[50px] w-[50px] flex justify-center items-center z-50"
                    @click="close_form"
                    style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">

                <font-awesome-icon
                    :icon="['fas', 'times']"
                    class="text-xl"
                    :class="`text-${ site.color }`"
                />

            </button>

            <div class="p-3 bg-white relative z-40 shadow-popup">
                <div  class="bg-gray-lighter">
                    <div class="px-6 py-4">
                        <div class="flex flex-col items-stretch">


                            <div class="flex justify-between items-center mb-5">

                                <h1 class="text-xl sm:text-2xl  font-bold"
                                    :class="`text-${ site.color }`"
                                    v-html="sales_headline">
                                </h1>

                                <font-awesome-icon :icon="['fal', 'gift']"
                                                   class="text-3xl sm:block hidden"
                                                   :class="`text-${ site.color }`"
                                />

                            </div>


                            <Transition name="form" mode="out-in">
                                <form
                                    id="signup-form"
                                    @submit.prevent="register"
                                    class="sm:grid-cols-3 grid mb-4 grid-cols-1 gap-2 auto-rows-fr opacity-100"
                                    novalidate="true"
                                    v-if="!success_message && !confirm_message"
                                >
                                    <input class="rounded-sm shadow focus:outline-none p-3 h-full text-base"
                                           type="text"
                                           v-model="form.email"
                                           @input="reset_validation"
                                           :placeholder="__('Company E-mail')" />

                                    <input class="rounded-sm shadow focus:outline-none p-3 h-full text-base"
                                           type="password"
                                           v-model="form.password"
                                           @input="reset_validation"
                                           :placeholder="__('Password')" />

                                    <button type="submit" class="p-3 text-white rounded-sm text-lg font-bold"
                                            :class="`bg-${ site.color }`">

                                        <span v-if="!processing">{{ __('Sign up') }}</span>
                                        <span v-else>{{__('Processing')}}</span>

                                    </button>
                                </form>
                                <div v-else class="mb-4 flex items-stretch opacity-100 sm:flex-row flex-col">
                                    <div v-if="success_message" class="w-full rounded-md border-1 border-green-darker bg-opacity-10 py-4 px-5 flex justify-between items-center bg-green opacity-100 mb-2 sm:mb-0">
                                        <p class="flex sm:flex-row flex-col justify-center sm:items-center items-start">
                                            <font-awesome-icon class="text-green-darker text-xl mr-2 mb-2 sm:mb-0" :icon="['fa', 'check']"/>
                                            {{__(success_message)}}
                                        </p>
                                    </div>
                                    <div v-if="confirm_message" class="sm:w-2/3 w-full rounded-md border-1 border-yellow-300 bg-opacity-10 py-4 px-5 flex justify-between items-center bg-yellow-200 opacity-100 mb-2 sm:mb-0">
                                        <p>{{__(confirm_message)}}</p>
                                    </div>
                                    <Transition name="slide-right">
                                    <div class="flex flex-col justify-between sm:w-1/3 w-full sm:ml-2" v-if="!domain_does_exist && !is_confirmed">
                                        <button @click="success_message = null" class="p-3 text-black rounded-sm text-lg font-bold mb-2 border-gray-500 border">

                                            <span>{{ __('Cancel') }}</span>

                                        </button>
                                        <button @click="confirm_register" class="p-3 text-white rounded-sm text-lg font-bold"
                                                :class="`bg-${ site.color }`">

                                            <span>{{ __('Confirm') }}</span>

                                        </button>
                                    </div>
                                    </Transition>

                                </div>

                            </Transition>

                            <div class="flex justify-between">

                                <a class="font-bold underline" target="_blank" :href="terms_link">{{ __('Read terms and conditions') }}</a>

                                <div class="flex items-center">

                                    <font-awesome-icon
                                        :icon="['fas', 'lock']"
                                        size="sm"
                                        class="ml-auto text-green-light mr-2 -mt-1 hidden sm:block"
                                    />

                                    <div class="text-sm text-gray-light font-bold hidden sm:block">{{ __('Connection is encrypted') }}</div>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="px-7 py-3 flex"
                         :class="`bg-${ site.color }`"
                         v-if="is_unlimited_users_allowed">

                        <font-awesome-icon
                            :icon="['fas', 'check']"
                            class="text-white sm:text-md mr-3 text-sm"
                        />

                        <h2 class="text-white sm:text-md font-bold text-sm">{{ __('We can see you have free access in your workplace') }}</h2>

                    </div>
                    <div v-for="error in errors" class="px-7 py-3 flex bg-gradient-grey">

                        <font-awesome-icon
                            :icon="['fas', 'times']"
                            class="text-red sm:text-md mr-3 text-sm"
                        />

                        <h2 class="text-red sm:text-md font-bold text-sm">{{ __(error[0]) }}</h2>

                    </div>
                </div >

            </div>

        </div>
    </transition>
</template>

<script>
export default {
    name: "PopupForm",
    data: function() {
        return {
            form: {
                email : '',
                password : '',
            },
            errors: null,
            user: null,
            processing: false,
            success_message: '',
            confirm_message: null,
            show_form: true,
            domain_does_exist: true,
            show_confirm: false,
            is_confirmed: false
        };
    },
    props: [
        'sales_headline',
        'is_unlimited_users_allowed',
        'endpoint'
    ],
    async created() {


    },

    computed: {

        terms_link() {

            return this.site.terms_link;

        },
        success_class(){
            return {
                'sm:w-full' : this.is_confirmed || this.domain_does_exist
            }
        }

    },

    methods: {
        confirm_register: async function (e){
            this.is_confirmed = true;

            this.register(e);
        },
        register: async function (e) {

            let device_name = this.$store.getters.device_name;

            let data = this.form;

            data['device_name'] = device_name;

            data['is_confirmed'] = this.is_confirmed;

            let validated = false;

            this.processing = true;

            await window.paywall.axios.post(this.endpoint, data)
                .then(response => {
                    if (response.errors) {
                        this.errors = response.errors;
                    } else {

                        this.domain_does_exist = response.domain_does_exist;

                        this.confirm_message = response.confirm_message;

                        this.success_message = response.success_message;

                        if(this.domain_does_exist || this.is_confirmed){
                            this.success_message = response.success_message;

                            validated = true;

                            let token = response.token;

                            window.paywall.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                            localStorage.setItem('paywall.token', token);

                            this.is_logged_in = true;

                            if(response.domain_does_exist){
                                setTimeout(() => {
                                    this.show_form = false;
                                }, 4000);
                            }
                        }


                    }

                    this.processing = false;

                }).catch(error => {
                    console.log(error.response.status);
                });

            if(!this.domain_does_exist){
                this.show_confirm = true;

                localStorage.setItem('show_popup', false);
            }


        },
        close_form(){
            this.show_form = false;
            localStorage.setItem('popup_closed_at', Date.now());
        },
        reset_validation(){
            this.errors = null;
        }
    }
}
</script>

<style scoped>
.fade-slide-enter-active, .fade-slide-leave-active {
    transition: all 1s;
}
.fade-slide-enter, .fade-slide-leave-to{
    transform: translate3d(100%, 0, 0);
    opacity: 0;
}
@media only screen and (max-width: 640px) {
    .fade-slide-enter, .fade-slide-leave-to{
        transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
}
.form-enter-active,
.form-leave-active {
    transition: all 0.25s ease-out;
}

.form-enter-from,
.form-leave-to {
    opacity: 0!important;
}
.slide-right-active,
.slide-right-leave-active {
    transition: all 0.25s ease-out;
}

.slide-right-enter,
.slide-right-leave-to {
    transform: translateX(30px);
    opacity: 0!important;
}
</style>
