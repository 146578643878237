
export default {

    computed: {


    },

    methods: {

        async get_customer_profile() {

            let
                response
            ;


            response = await window.paywall.axios.get('/user/customer-profile');


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'profile' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'profile' );

                }

                return false;

            }


            return response;

        },


        async save_customer_profile(form) {

            let
                response
            ;


            response = await window.paywall.axios.patch('/user/customer-profile', form);


            if ( response.hasOwnProperty('message') ) {

                if ( 'errors' in response ) {

                    for ( const error of paywall._.values( response.errors ) )
                    {
                        this.push_error_message( error[0].toString(), false,'profile' );
                    }

                }
                else {

                    this.push_error_message( response.message, false, 'profile' );

                }

                return false;

            }


            this.push_success_message (this.__('We have saved your profile info'), false, 'profile' );

        },

    }
}
