<template>

    <div class="relative w-full">

        <div
            class="px-5 sm:px-0"
            :style="short_story_styles"
            v-show="is_subscription_access === false && is_paywall_deactivated === false"
            v-html="short_story">
        </div>

        <div id="paywall_tailwind">

            <div class="top-0 w-full flex flex-col items-start justify-start z-10 bg-transparent pb-10"
                 :class="{
                    'pt-12': ! is_short_hidden(),
                    'pt-0': is_short_hidden(),
                    'absolute': is_shaded_short_story,
                    'relative': ! is_shaded_short_story,
                 }"
                 v-if="is_subscription_access === false && is_paywall_deactivated === false">

                <div id="top_shade_message"
                     class="w-full flex justify-start items-end "
                     :class="{
                        'h-[134px]': ! is_short_hidden() && is_shaded_short_story,
                        'h-[30px]': ! is_short_hidden() && ! is_shaded_short_story,
                        'h-[54px]': is_short_hidden(),
                     }"
                     style="background: linear-gradient(0deg, #FFFFFF 33.5%, rgba(255, 255, 255, 0) 100%);">

                    <h2 class="py-4 font-bold text-2xl px-5 sm:px-0"
                        :class="`text-${ site.color }`">

                        {{ __( headline ) }}?

                    </h2>

                </div>


                <div
                    class="bg-white w-full pb-8">

                    <a
                        class="block border-green-dark border-2 w-full bg-green-dark bg-opacity-10  flex flex-col p-5 cursor-pointer"
                        :href="trial_url">

                        <div class="flex flex-col sm:flex-row justify-between w-full items-center mb-6 sm:mb-0">

                            <div class="flex flex-col mb-4 sm:mb-none">

                                <h2 class="text-black font-bold w-full text-xl mb-2">{{ __(site.trial_week_count === 1 ? 'Try :site_name free for :trail_week_count week' : 'Try :site_name free for :trail_week_count weeks', {
                                    'site_name': site.name,
                                    'trail_week_count': site.trial_week_count
                                }) }}</h2>

                                <div class="text-sm text-gray-dark">{{ __('Get access right away - not binding - no creditcard') }}</div>

                            </div>

                            <div class="text-lg font-bold"
                                 style="width: fit-content;">{{ __('Free') }}</div>

                        </div>


                        <div
                            class="grid grid-cols-1 sm:grid-rows-1 gap-3"
                            :class="`grid-rows-${ site.trial_subscription_pros.length } sm:grid-cols-${ site.trial_subscription_pros.length }`">

                            <span class="hidden sm:grid-cols-4 sm:grid-cols-3 sm:grid-cols-2 sm:grid-cols-1" />
                            <span class="hidden grid-rows-1 grid-rows-2 grid-rows-3 grid-rows-4" />

                            <div class="bg-white flex flex-col justify-start items-center p-3 text-center shadow"
                                 v-for="pro of site.trial_subscription_pros">

                                <font-awesome-icon
                                    :icon="['fal', pro.icon]"
                                    size="2x"
                                    fixed-width
                                    class="mb-3">
                                </font-awesome-icon>

                                <div class="text-xs"
                                     v-html="__(pro.text, {
                                         trial_week_count: site.trial_week_count,
                                         newsletter_frequency: __(site.newsletter_frequency)
                                     })"
                                />

                            </div>

                        </div>

                    </a>

                </div>


                <LoginWidget
                    class="mb-25"
                />


                <div
                    class="bg-white w-full flex flex-col sm:flex-row justify-start items-start sm:items-center px-4 py-4"
                    v-if="is_logged_in">

                    <p class="text-sm mr-6 mb-4 sm:mb-0 leading-6">

                        {{ __('You do not have access to this content with your current active subscriptions.') }}

                    </p>

                    <a
                        class="block border-2 border-black text-white hover:bg-white hover:text-black w-min sm:ml-auto mb-4 sm:mb-0 whitespace-nowrap font-bold px-4 py-4 text-lg relative mr-4"
                        :class="`bg-${ site.color } hover:text-${ site.color }`"
                        :href="account_url">

                        <span>{{ __('Account') }}</span>

                    </a>

                    <button
                        class="bg-red border-2 border-red text-white hover:bg-white hover:text-black w-min whitespace-nowrap font-bold px-4 py-4 text-lg relative mb-4 sm:mb-0"
                        @click.prevent="handle_log_out">

                        <loader
                            :on="loading"
                        />

                        <span>{{ __('Log out') }}</span>

                    </button>

                </div>


                <messages
                    class="bg-white w-full"
                    :types="['authentication','paywall']"
                />


            </div>

        </div>

    </div>

</template>

<script>
import LoginWidget from "../widgets/LoginWidget";

export default {

    name: 'PaywallView',

    components: {
        LoginWidget
    },

    props: {
        is_paywall: {
            type: [Boolean, String, Number],
            default: false
        },
        type: {
            type: String,
            default: 'article'
        },
        headline: {
            type: String,
            default: 'Want to read the rest of the story'
        },
        _site: {
            type: String,
            default: 'false'
        }
    },

    data() {
        return {
            loading: false,
            original_story: '',
            short_story: '',
            is_shaded_short_story: true,
            is_mobile_width: false,
            pros: [
                {
                    text: 'Nyhedsbrev :trial_week_count <br> dage om :newsletter_frequency',
                    icon: 'envelope'
                },
                {
                    text: 'Adgang til alle <br> artikler',
                    icon: 'newspaper'
                },
                {
                    text: 'Adgang til alle <br> Ritzau artikler',
                    icon: 'unlock'
                },
                {
                    text: 'Samarbejdspartneres <br> artikler',
                    icon: 'handshake'
                }
            ],
            subscription_watcher: null
        }
    },

    created() {                                             //console.log('Creating PaywallView');

        if ( this._site !== 'false' ) {

            this.site = JSON.parse(this._site);

        }


        this.is_mobile_width = window.matchMedia('(max-width: 639px)').matches;


        this.subscription_watcher = this.$store.watch(
            (state) => state.is_subscription_access,
            (val) => this.is_subscription_access_watcher(val)
        );

        if ( this.site.slug === 'nb_data'  ) {

            this.short_story = `
                <div class="p-4 border-primary border mt-12">
                <h2 class="text-primary mb-4 text-2xl font-bold">NB-Data</h2>
                <p class="mb-4 text-primary font-bold">Med et <strong>NB-Data</strong> abonnement kan din organisation få adgang til data i Excel, vores landkort og øvrige grafikker.</p>
                <p class="mb-4 text-primary font-bold">Du får også gratis adgang til særlige webinarer, hvor du efter store politiske forlig mv. har mulighed for at stille supplerende spørgsmål til den journalist, som har dækket sagen.</p>
                <p class="mb-4 text-primary font-bold">Endeligt har organisationer med NB-Data abonnement ret til vederlagsfrit at lægge tekst og grafik fra op til 10 artikler ud på intranet eller hjemmesider (gælder ikke billeder pga. ophavsret og gælder kun artikler produceret af NB-Medier).</p>
                <p class="mb-4 text-primary font-bold">Hvis din organisation ikke allerede har abonnement, så har vi netop nu <span class="underline">et introduktionstilbud på 6.000 kroner excl. moms</span> for et år (normalpris 7995).</p>
                <p class=" text-primary font-bold">Vi opretter enten IP-adgang eller kobler abonnementet på op til 10 brugere.</p>
                <p class=" text-primary font-bold">Send mail til <a style="text-decoration: underline;" href="mailto:hellew@nb-medier.dk">hellew@nb-medier.dk</a></p>
                </div>
            `;

            this.is_shaded_short_story = false;

        }

        this.fetch_is_subscription_access();

        this.update_is_paywall_deactivated();

        if (
            this.is_paywall === '1'
            || this.is_paywall === true
            || this.is_paywall === 1
        ) {                                                 //console.log('Deactivating paywall');

            this.is_paywall_deactivated = true;

        }

        this.is_subscription_access_watcher(this.is_subscription_access);

        // this.original_story = '<div>original story</div>';

        // this.short_story = '<div>short story</div>';
                                                            //console.log('Created PaywallView');
    },

    beforeDestroy() {

        this.subscription_watcher();

    },

    mounted() {                                             //console.log('Mounting PaywallView');

        if ( this.type === 'article' ) {

            this.original_story = window.paywall._.clone(
                document.getElementById('the_story').innerHTML
            );

            this.short_story = window.paywall._.truncate( this.original_story, {
                length: this.get_length_by_site(),
            });

        }
        else {

            this.is_subscription_access_watcher(this.is_subscription_access);

        }

    },

    computed: {

        short_story_styles() {

            let styles = '';

            if ( this.is_shaded_short_story ) {

                styles += 'min-height: 350px;';

            }

            if ( this.is_mobile_width && this.is_shaded_short_story ) {

                styles += 'margin-bottom: 920px;';

            }
            else if ( this.is_mobile_width && ! this.is_shaded_short_story ) {

                styles += 'margin-bottom: 20px;';

            }
            else if ( ! this.is_mobile_width && this.is_shaded_short_story ) {

                styles += 'margin-bottom: 420px;';

            }

            return styles;

        },

    },

    methods: {

        hide_the_story() {

            document.getElementById('the_story').style.display = 'none';

        },

        show_the_story() {

            document.getElementById('the_story').style.display = 'block';

        },

        is_subscription_access_watcher(val) {                   //console.log('is_subscription_access changed', val);

            if ( val === true || this.is_paywall_deactivated === true ) {

                this.show_the_story();

            }
            else {

                this.hide_the_story();

            }

        },

        async handle_log_out() {

            this.loading = true;

            this.push_normal_message(this.__('Wait! We are processing the log out.'), true, 'paywall');

            await this.log_out();

            this.clear_messages(0,'paywall');

            this.clear_messages(3,'authentication');

            this.loading = false;

        },

        is_short_hidden() {

            return this.site.id === "5";

        },

        get_length_by_site() {

            if (
                this.is_short_hidden()
            ) {

                return 0;

            }

            return 600;

        },

    }

}
</script>
