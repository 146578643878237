<template>

    <div id="paywall_tailwind">

        <div class="relative leading-none py-3 px-2 text-sm text-black font-bold">

            <button class="mr-2"
                :class="button_classes"
                @click="navigate_to_try_free_subscription"
                v-if="! is_subscription_access">

                {{ __('Try :site_name', {
                    site_name
                }) }}

            </button>

            <button class="mr-2"
                :class="button_classes"
                @click="navigate_to_account">

                {{ __('Account') }}

            </button>

            <button class="text-red"
                :class="button_classes"
                v-if="is_logged_in"
                @click="handle_log_out">

                {{ __('Log out') }}

            </button>

        </div>

    </div>

</template>

<script>
export default {

    name: "MenuView",

    props: {

        button_classes: {
            type: String,
            default: ''
        }

    },

    methods: {

        async handle_log_out() {

            this.loading = true;

            this.current_page = 'login';

            this.push_normal_message(this.__('Wait! We are processing the log out.'), true, 'paywall');

            await this.log_out();

            this.clear_messages(0,'paywall');

            this.clear_messages(3,'authentication');

            this.loading = false;

        },

    }

}
</script>

<style scoped>

</style>
