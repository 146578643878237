<template>

    <div id="paywall_tailwind">

        <p class="font-bold text-[18px] my-4"
            :class="`text-${ site.color }`">

            Beskriv kampagne

        </p>

        <form @submit.prevent="forms_submit(
            'advertisement_form',
            'We have received your request and will get back to you as soon as possible'
        )">

            <textarea
                class="appearance-none block w-full bg-white text-gray-700 border border-1 border-[#C1C1C1] min-h-[150px] py-3 px-4 my-4 leading-tight focus:outline-none focus:bg-white focus:ring-dark-blue"
                placeholder="Kampagnebeskrivelse"
                v-model="advertisement_form.description"
            />

            <FormInputField
                class="mb-4"
                placeholder="Navn"
                type="text"
                :required="true"
                v-model="advertisement_form.name"
            />

            <FormInputField
                class="mb-4"
                placeholder="Telefonnummer"
                type="tel"
                pattern="[2-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}"
                :required="true"
                v-model="advertisement_form.phone_number"
            />

            <FormInputField
                class="mb-4"
                placeholder="E-mail"
                type="text"
                :required="true"
                v-model="advertisement_form.email"
            />

            <button class=" hover:bg-white border-2  hover:border-2 w-full text-white py-4 px-4 text-[20px] leading-tight"
                :class="`bg-${ site.color } border-${ site.color } hover:text-${ site.color }`"
                type="submit">

                <p class="font-medium">Send forespørgsel</p>

            </button>

        </form>

        <messages
            class="bg-white w-full mt-2"
            :types="['advertisement_form']"
        ></messages>

    </div>

</template>

<script>
import FormInputField from "../components/FormInputField.vue";

export default {

    name: "AdvertisementFormView",

    components: {
        FormInputField
    },

    async created() {

        let
            profile_form = await this.get_customer_profile(),
            default_form = this.advertisement_form
        ;

        this.advertisement_form = {
            ...default_form,
            email: profile_form.email,
            phone_number: profile_form.phone,
            name: profile_form.full_name,
            token: localStorage.getItem('paywall.token'),
            site_id: this.site.id
        };

    },

}
</script>

<style scoped>

</style>
