<template>

    <div class="flex w-full"
         v-show="messages.length > 0">

        <div class="list-disc pl-10 mb-3"
             v-show="messages.length > 0">

            <div class="list-item"
                :class="`text-${ message.color }-600`"
                v-for="message in messages"
                v-if="types.indexOf(message.type) > -1 || message.type === 'any'">

                <span v-html="message.text" />

                <font-awesome-icon
                    v-show="message.spin"
                    :icon="['fad', 'spinner-third']"
                    spin
                    size="sm"
                ></font-awesome-icon>

            </div>

        </div>

    </div>

</template>

<script>
export default {

    name: "Messages",

    props: {

        types: {
            type: Array,
            default: () => {
                return []
            }
        }

    },

}
</script>
