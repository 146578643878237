<template>

    <div class="absolute top-0 left-0 w-full h-full z-10 flex justify-center items-center"
        :class="`bg-${ site.color }`"
        v-if="on">

        <div class="bg-purple bg-red bg-blue text-blue text-red text-purple hidden"></div>

        <font-awesome-icon
            :icon="['fad', 'spinner-third']"
            size="lg"
            spin
            class="text-white"
        ></font-awesome-icon>

    </div>

</template>

<script>

export default {

    name: "Loader",

    props: {

        on: {
            type: Boolean,
            default: false
        },

    }

}

</script>

<style scoped>

</style>
