<template>

    <div class="bg-white w-full flex flex-col border-b-1 border-[#C1C1C1]"
         v-if="! is_logged_in">

        <div class="hidden bg-white text-grey"></div>

        <div class="flex items-center">

            <div
                class="text-lg px-5 py-4 font-bold border-r-1 border-[#C1C1C1] hover:bg-[#C1C1C1] cursor-pointer select-none"
                :class="{
                    'border-b-0 border-t-1 border-l-1': active_tab === 'login',
                    'border-b-1 border-t-0': active_tab !== 'login'
                }"
                @click="activate_tab('login')">

                {{ __('Login') }}

            </div>

            <div
                class="text-lg px-5 py-4 font-bold border-[#C1C1C1] hover:bg-[#C1C1C1] cursor-pointer select-none"
                :class="{
                        'border-b-0 border-t-1 border-r-1': active_tab === 'forgot_password',
                        'border-b-1 border-t-0': active_tab !== 'forgot_password'
                }"
                @click="activate_tab('forgot_password')"
            >{{ __('Forgot password') }}?</div>

            <div class="h-[62px] border-b-1 border-[#C1C1C1] flex items-center login_middle_box">

                <font-awesome-icon
                    :icon="['fas', 'lock']"
                    size="sm"
                    class="ml-auto text-green-light mr-2 -mt-1 hidden sm:block"
                ></font-awesome-icon>

            </div>

            <div class="flex items-center h-[62px] border-b-1 border-[#C1C1C1]">

                <p class="text-sm font-bold mr-5 hidden sm:block">{{ __('Connection is encrypted') }}</p>

            </div>

        </div>

        <div class="flex bg-white w-full px-5 py-4 border-l-1 border-r-1 border-[#C1C1C1]"
             v-if="active_tab === 'login'">

            <form class="w-full flex flex-col sm:flex-row"
                  @submit.prevent="! loading ? handle_login() : ''" >

                <div class="flex flex-col sm:flex-row w-full relative"
                    id="login_fields">

                    <input class="border-1 border-[#C1C1C1] p-3 mr-4 w-full sm:w-1/2 text-base leading-6 h-auto  w-full sm:w-1/2 mb-4 sm:mb-auto"
                        id="email"
                        type="text"
                        aria-required="true"
                        autocomplete="email"
                        :placeholder="__('Email')"
                        v-model="form.email"
                    />

                    <input class="border-1 border-[#C1C1C1] p-3 mr-4 w-full sm:w-1/2 text-base leading-6 h-auto  w-full sm:w-1/2 mb-4 sm:mb-auto"
                        type="password"
                        id="password"
                        aria-required="true"
                        :placeholder="__('Password')"
                        v-model="form.password"
                        autocomplete="current-password"
                    />

                </div>

                <button class="border-2 border-black text-white hover:bg-white whitespace-nowrap font-bold px-4 py-3 text-lg sm:ml-auto relative z-0 w-full sm:w-min"
                    :class="`bg-${ site.color } hover:text-${ site.color }`"
                    type="submit">

                    <loader
                        :on="loading"
                    />

                    <span>{{ __('Login') }}</span>

                </button>

            </form>

        </div>

        <div class="flex flex-col bg-white w-full px-5 py-4 border-l-1 border-r-1 border-[#C1C1C1]"
             v-if="active_tab === 'forgot_password'">

            <form class="w-full flex mb-4 sm:flex-row flex-col"
                  @submit.prevent="! loading ? handle_forgot_password() : ''">

                <div class="flex flex-col w-full"
                    id="forgot_fields">

                    <input
                        class="border-1 border-[#C1C1C1] p-3 mr-4 w-full sm:w-auto text-base leading-6 h-auto mb-4 sm:mb-auto"
                        type="text"
                        placeholder="E-mail"
                        autocomplete="email"
                        v-model="form.email" />

                </div>

                <button class="border-2 border-black text-white hover:bg-white whitespace-nowrap sm:ml-auto font-bold px-4 py-3 text-lg relative w-full sm:w-min"
                    :class="`bg-${ site.color } hover:text-${ site.color }`"
                    type="submit">

                    <loader
                        :on="loading"
                    />

                    <span>{{ __('Send login') }}</span>

                </button>

            </form>

            <p class="w-full text-sm mb-0">

                <a
                   class="text-blue-400 underline hover:no-underline"
                   href="/kontakt-os/#kundeservice"
                   @click="navigate_to_contact">

                    {{ __('Still trouble logging in contact us here') }}

                </a>

            </p>

        </div>

    </div>

</template>

<script>
export default {

    name: "LoginWidget",

    data() {
        return {
            loading: false,
            active_tab: 'login',
            form: {
                email: '',
                password: '',
                device_name: ''
            },
        }
    },

    methods: {

        activate_tab( tab ) {                               console.log('activate_tab called: ' + tab);

            this.active_tab = tab;

        },

        async handle_login() {                                                                                          let debug_id = Debug.init_method('handle_login',true);

            let
                response
            ;

            this.loading = true;

            this.push_normal_message(this.__('Wait! We are processing the login.'), true, 'paywall');

            response = await this.login(this.form);                                                                     Debug.log(debug_id,168,response);

            this.clear_messages(0,'paywall');

            this.clear_messages(5,'authentication');

            if ( ! response ) {                                                                                         Debug.log(debug_id,174,true);

                this.is_subscription_access = false;

                this.loading = false;

                return;

            }

            this.form = {
                email: '',
                password: '',
                device_name: ''
            };

            response = await this.fetch_is_subscription_access();                                                       Debug.log(debug_id,191,response);

            this.$emit('login_complete');

            this.loading = false;

        },


        async handle_log_out() {

            this.loading = true;

            this.push_normal_message(this.__('Wait! We are processing the log out.'), true, 'paywall');

            await this.log_out();

            this.clear_messages(0,'paywall');

            this.clear_messages(5,'authentication');

            this.loading = false;

        },


        async handle_forgot_password()
        {
            let
                response
            ;

            this.loading = true;

            this.push_normal_message('Wait! We are sending you an e-mail.',true,'paywall');

            response = await this.send_forgot_password(this.form);

            this.clear_messages(0,'paywall');

            this.clear_messages(5,'authentication');

            this.loading = false;

        },

    }

}
</script>

<style>

.login_middle_box {
    width: calc(100% - 301px);
}

@media (min-width: 640px) {

    .login_middle_box {
        width: calc(100% - 114px - 262px - 120px);
    }

    #login_fields {
        width: calc(100% - 95px)!important;
    }

    #forgot_fields {
        width: calc(100% - 138px)!important;
    }

}

</style>
