export default {

    init() {

        this.state = {
            debug_activated: false,
            methods: [],
            current_method_id: false,
        };

        return this;

    },

    start() {                                         //console.log('starting Debug'); console.log(activated); console.log({ ...this });

        this.state.debug_activated = true;

    },

    stop() {

        this.state.debug_activated = false;

        console.groupEnd();

    },

    init_method( method = false, activate = false, value = true,show = true ) {

        if ( ! ('state' in this) ) {                                                        //console.log('state in this == false');

            return false;

        }

        if ( ! this.state.debug_activated ) {                                               //console.log('debug_activated == false');

            return false;

        }

        if ( method === false ) {                                                               //console.log(true);

            return false;

        }

        let id = this.state.methods.push({
            method: method,
            activate: activate,
            count: 0,
        });                                                                                //console.log(id);

        id = id - 1;

        this.log( id, 0, value, show );

        return id;

    },

    log( method_id = false, line = false, value = false, show = true ) {        //console.log(value); console.log(method_id);

        if ( ! show ) {

            return;

        }

        if ( ! ('state' in this) ) {                                                        //console.log('state in this == false');

            return;

        }

        if ( ! this.state.debug_activated ) {                                               //console.log('debug_activated == false');

            return;

        }

        if ( method_id === false ) {                                                               //console.log(true);

            return;

        }                                                                                   //console.log(this.state.methods[ method_id ]);
                                                                                            //console.log(this.state.methods);

        if ( this.state.methods[ method_id ] === undefined ) {                              console.error('methods not implemented correctly');

            return;

        }

        if ( ! this.state.methods[ method_id ].activate ) {                                 //console.log(true);

            if (
                this.state.current_method_id === false
            ) {

                // Start - new logging empty group
                console.groupCollapsed('Debug: ' + this.state.methods[ method_id ].method );

                this.state.current_method_id = method_id;

            }
            else if (
                method_id !== this.state.current_method_id
            ) {

                // End - previous logging
                console.groupEnd();

                // Restart - new logging
                console.groupCollapsed('Debug: ' + this.state.methods[ method_id ].method );

                this.state.current_method_id = method_id;

            }
            else if (
                method_id === this.state.current_method_id
            ) {

                // Nothing, just continue

            }


            return;

        }


        // Focused testing by method name
        if (
            ! paywall._.isEmpty( sessionStorage.test )
            && sessionStorage.test !== this.state.methods[ method_id ].method
        ) {

            return;

        }


        // Prepare - value accordingly
        if ( 'object' === typeof value ) {

            value = paywall._.clone(value);

        }


        // Print - logging
        if ( this.state.current_method_id === false ) {                                             //console.log('method == true && this.state.method == false');

            this.state.current_method_id = method_id;


            // Start - new logging
            console.groupCollapsed('Debug: ' + this.state.methods[ method_id ].method );

            console.trace();

            console.debug('LINE: ' + line + ', COUNT: ' + this.state.methods[ method_id ].count);

            console.debug(value);

        }
        else if (
            method_id !== this.state.current_method_id
        ) {                                                                                                             //console.log('method == true && this.state.method == true');

            this.state.current_method_id = method_id;


            // End - previous logging
            console.groupEnd();


            // Restart - new logging
            console.groupCollapsed('Debug: ' + this.state.methods[ method_id ].method );

            console.trace();

            console.debug('LINE: ' + line + ', COUNT: ' + this.state.methods[ method_id ].count);

            console.debug(value);

        }
        else if (
            method_id === this.state.current_method_id
        ) {                                                                                                             //console.log('else debug');

            // Log - inside group
            this.state.methods[ method_id ].count++;

            console.debug('LINE: ' + line + ', COUNT: ' + this.state.methods[ method_id ].count);

            console.debug(value);

        }

    }

}
